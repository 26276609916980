// --bs-body-color: #5a6770;

.euiHeaderLogo__icon {
  display: none !important;
}

button,
a {
  &:hover {
    text-decoration: none !important;
  }
}

body a {
  color: $accent;
}

.notes {
  .cards {
    width: 100%;
    flex-wrap: nowrap !important;
    overflow: auto;
  }
  .card {
    width: auto !important;
    min-width: 280px;
  }
}

.euiBreadcrumbs__list {
  background: transparent;
}

.description {
  color: #5a6770 !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
  color: #152934;
}

body h2 {
  font-size: 24px;
  line-height: 38px;
}

body h3 {
  font-size: 16px;
  line-height: 24px;
}

.euiTitle {
  color: #152934;
}

.euiTitle[class*='-euiTitle-m'] {
  color: #152934;
}
.normal {
  color: #909ba3 !important;
}

.css-wrotw4-euiButtonDisplay-m-m-fill-primary {
  color: white !important;
  border-radius: 4px !important;
}

.partial-delivery,
.login,
.full-form-row {
  .euiFormRow,
  .euiFormControlLayout,
  .euiFieldText,
  .euiFieldPassword {
    max-width: inherit !important;
    width: 100% !important;
  }
}

.login {
  min-height: 100vh;
}

.euiFormLabel.euiFormLabel-isFocused {
  color: $accent !important;
}

.css-1pubxc-euiAvatar-m-user {
  color: white !important;
}

.quick-links .euiIcon {
  color: white !important;
}

.euiHeaderSectionItemButton__content {
  color: initial !important;
}

.border-top {
  border-top: 1px solid #eeeeee !important;
}

@media screen and (max-width: 600px) {
  .md-display-none {
    display: none !important;
  }
}

.euiKeyPadMenuItem:not(.euiKeyPadMenuItem-isDisabled):focus {
  background-color: #ed1c250a;
}

.css-1r1utll-euiIcon-l-app .euiIcon__fillSecondary {
  fill: $accent;
}

.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
  border-color: $accent !important;
  background-color: $accent !important;
}

.ui.celled.grid > .column:not(.row),
.ui.celled.grid > .row > .column {
  box-shadow: none !important;
}

// .register {
//   .euiFormControlLayout {
//     margin-bottom: 8px !important;
//   }
// }

.euiFieldText:invalid,
.euiFieldNumber:invalid,
.euiFieldPassword:invalid {
  background-image: none !important;
}

.euiHeaderLink-isActive {
  background-color: rgba(252, 66, 66, 0.05);
}

.euiHeaderLink {
  &:hover {
    color: $accent;
  }
}

.euiButton {
  border-radius: 4px !important;
  color: white !important;
  background-color: $accent !important;
  font-weight: bold !important;
}

button.outlined,
a.outlined {
  background-color: transparent !important;
  border: 1px solid $accent2;
  color: $accent !important;
  font-weight: bold !important;
  border-radius: 4px !important;

  &:hover {
    background-color: $accent !important;
    color: white !important;
  }
}

.btn-outline-primary:hover {
  color: white !important;
  border-color: $accent !important;
}

button.compressed,
a.compressed {
  font-size: 0.875rem !important;
  min-inline-size: 100px;
  block-size: 35px !important;
}

button.collapsed,
a.collapsed {
  font-size: 0.875rem !important;
  min-inline-size: 80px;
  block-size: 28px !important;
}

// [class*="euiPageHeaderContent-border-l"] {
//   border-block-end: 1px solid red;
// }

@media screen and (max-width: 600px) {
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.euiIcon__fillSecondary {
  fill: $accent !important;
}

[class*='-euiButtonDisplayContent'] {
  font-weight: bold !important;
}

.euiTableRow {
  &:last-child .euiTableRowCell {
    border-bottom: none !important;
  }
}

.euiTableHeaderCell {
  background: #ebeef0 !important;
  & .euiTableCellContent__text {
    font-size: 14px !important;
  }
}

input:-internal-autofill-selected,
textarea:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: white !important;
  color: fieldtext !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  // -webkit-box-shadow: 0 0 0 30px white !important;
}

.euiFormControlLayout {
  border-radius: 4px !important;
  // box-shadow: 0 0 transparent, inset 0 0 0 1px rgb(17 43 134 / 10%) !important;
}

.euiFieldText {
  border-radius: 4px !important;
}

.euiSuperSelectControl {
  background-image: linear-gradient(
    to top,
    #e83330,
    #e83330 2px,
    transparent 2px,
    transparent 100%
  ) !important;
}

.euiToolTipAnchor {
  margin-bottom: -2px;
}

.ant-select,
.ant-select-selector {
  outline: none !important;
  border: none !important;
  border-radius: 6px;
  box-shadow: 0 0 transparent, inset 0 0 0 1px rgb(17 43 134 / 10%) !important;
}

.delivery-details-table {
  &.table,
  &.table tr,
  &.table tr td {
    border: none !important;
    td {
      padding: 8px !important;
    }

    &:last-child {
      border-bottom: 1px dashed $border-color !important;
    }
  }
  &.table tr:last-child td:last-child {
    border-bottom: none !important;
  }
  &.table tfoot th {
    border-top: 1px dashed $border-color !important;
  }
}

.euiRadio .euiRadio__input:checked + .euiRadio__circle {
  border-color: #e83330 !important;
  background-color: #e83330 !important;
}

fieldset {
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;

  .euiRadio {
    margin-right: 8px;
    margin-top: 0 !important;
  }
  .euiFormLegend {
    flex: 1 1 100% !important;
  }
}

.payment-method-modal {
  input.euiFieldText {
    background-color: white !important;
  }
}

@media screen and (max-width: 600px) {
  .ui.delivery-details-table.table:not(.unstackable),
  .ui.collapsed-in-mobile.table:not(.unstackable) {
    & tr,
    & th {
      display: flex !important;
      justify-content: space-between !important;
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
  }
  .navbar-brand {
    margin-right: 0 !important;
  }
  .btn {
    padding: 8px !important;
  }
  .notes .cards .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .table > :not(:first-child) {
    border: none !important;
  }

  .euiRadio {
    flex-wrap: wrap;
  }
}

.ui.steps {
  width: 100%;
  border-color: #dfdfdf !important;
  .step.active {
    background: #8bc34a1a !important;
    background: transparent !important;
  }
  .step {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.euiTab-isSelected {
  box-shadow: inset 0 calc(2px * -1) 0 $accent !important;
}

.ant-tag {
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  white-space: break-spaces !important;
  a {
    color: inherit;
    font-size: 0.75rem;
  }
}

.euiHeaderLogo__text {
  padding-left: 0 !important;
}

.euiPageTemplate {
  // padding: 0 !important;
  background: rgb(252, 252, 252) !important;
}

main {
  background: white;
}

.euiFilePicker__showDrop .euiFilePicker__prompt,
.euiFilePicker__input:focus + .euiFilePicker__prompt {
  background-image: linear-gradient(
    to top,
    $accent,
    $accent 2px,
    transparent 2px,
    transparent 100%
  ) !important;
}

.step .content {
  height: 100% !important;
  .flex,
  .d-flex {
    height: 100% !important;
    justify-content: space-between !important;
  }
}
