html {
  scroll-behavior: smooth;
}
vh-100 {
  height: 100vh;
}
#serviceTopSec {
  height: 68px;
}
.banner-left-top {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.banner-left-top-img {
  height: 300px;
  text-align: center;
  padding-bottom: 20px;
}
.banner-top-img {
  display: none;
  height: 100%;
}
.banner-right-inside-slider {
  position: absolute;
  width: 28.4%;
  height: 63.4%;
  left: 41.9%;
  top: 18.6%;
  z-index: 0;
  background: #ff00007d;
}
.banner-right-img {
  z-index: 1;
  position: relative;
}
.slick-next:before,
.slick-prev:before {
  display: none;
}
.card-heading-2 {
  font-size: 15px;
  color: #000;
  font-weight: 400;
}
.margin-left {
  margin-left: 50px !important;
}
.mobile-frame-img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
}
.mobile-slider-main-container {
  position: relative;
}
.hero-section {
  min-height: initial;
  padding: 5px 50px;
//   background-image: linear-gradient(
//       rgb(255 255 255 / 0.9),
//       rgb(243 243 243 / 0.9)
//     ),
//     url(../../assets/images/home/atc-Recovered-03.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.banner-padding {
  display: flex;
  align-items: center !important;
  height: 500px;
}
.banner-img {
  margin-right: auto;
  width: 100%;
}
.headline-text {
  font-size: 35px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}
.headline-desc {
  color: #fff;
  font-size: 20px;
}
input[type='text'] {
  background-color: #e0e0e0;
}
.faa {
  font-size: 40px;
  color: #d10513;
}
.why-choose-img {
//   background: url(../../assets/images/home/about-men.png) center bottom;
//   background-repeat: no-repeat;
  background-size: contain;
}
.testim {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
//   background-image: linear-gradient(rgba(0, 0, 0, 0.705), rgba(0, 0, 0, 0.705)),
    // url(../../assets/images/main/feedback/feedbaack-1.jpg);
  width: 100%;
  position: relative;
  top: 50%;
}
.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 40px 20px;
  margin: auto;
}
.testim .arrow {
  display: block;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 22222222;
}
.testim .arrow:before {
  cursor: pointer;
}
.testim .arrow:hover {
  color: #ea830e;
}
.testim .arrow.left {
  left: 10px;
}
.testim .arrow.right {
  right: 10px;
}
.testim .dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0;
  display: block;
  z-index: 3333;
  height: 12px;
}
.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #eee;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}
.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #ea830e;
  border-color: #ea830e;
}
.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
}
.testim .cont {
  position: relative;
  overflow: hidden;
}
.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 70px 0;
  opacity: 0;
}
.testim .cont > div.inactive {
  opacity: 1;
  margin-top: 100px;
}
.testim .cont > div.active {
  position: relative;
  opacity: 1;
  margin-top: 100px;
}
.testim .cont div .img img {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
}
.testim .cont div h2 {
  color: #ea830e;
  font-size: 1em;
  margin: 15px 0;
}
.testim .cont div p {
  font-size: 1.15em;
  color: #eee;
  width: 80%;
  margin: auto;
}
.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}
.testim .cont div.active h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}
.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}
.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}
.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}
.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}
@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0 0 0 0 #eee;
    box-shadow: 0 0 0 0 #eee;
  }
  35% {
    -webkit-box-shadow: 0 0 10px 5px #eee;
    box-shadow: 0 0 10px 5px #eee;
  }
  70% {
    -webkit-box-shadow: 0 0 10px 5px #ea830e;
    box-shadow: 0 0 10px 5px #ea830e;
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 #ea830e;
    box-shadow: 0 0 0 0 #ea830e;
  }
}
@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0 0 0 0 #eee;
    box-shadow: 0 0 0 0 #eee;
  }
  35% {
    -moz-box-shadow: 0 0 10px 5px #eee;
    box-shadow: 0 0 10px 5px #eee;
  }
  70% {
    -moz-box-shadow: 0 0 10px 5px #ea830e;
    box-shadow: 0 0 10px 5px #ea830e;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #ea830e;
    box-shadow: 0 0 0 0 #ea830e;
  }
}
@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0 0 0 0 #eee;
    box-shadow: 0 0 0 0 #eee;
  }
  35% {
    -ms-box-shadow: 0 0 10px 5px #eee;
    box-shadow: 0 0 10px 5px #eee;
  }
  70% {
    -ms-box-shadow: 0 0 10px 5px #ea830e;
    box-shadow: 0 0 10px 5px #ea830e;
  }
  100% {
    -ms-box-shadow: 0 0 0 0 #ea830e;
    box-shadow: 0 0 0 0 #ea830e;
  }
}
@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0 0 0 0 #eee;
    box-shadow: 0 0 0 0 #eee;
  }
  35% {
    -o-box-shadow: 0 0 10px 5px #eee;
    box-shadow: 0 0 10px 5px #eee;
  }
  70% {
    -o-box-shadow: 0 0 10px 5px #ea830e;
    box-shadow: 0 0 10px 5px #ea830e;
  }
  100% {
    -o-box-shadow: 0 0 0 0 #ea830e;
    box-shadow: 0 0 0 0 #ea830e;
  }
}
@keyframes testim-scale {
  0% {
    box-shadow: 0 0 0 0 #eee;
  }
  35% {
    box-shadow: 0 0 10px 5px #eee;
  }
  70% {
    box-shadow: 0 0 10px 5px #ea830e;
  }
  100% {
    box-shadow: 0 0 0 0 #ea830e;
  }
}
@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}
@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}
@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}
@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}
@media all and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }
  .testim .cont div p {
    line-height: 25px;
  }
}
.card-heading {
  font-size: 25px;
  font-weight: 600;
}
.slider-sec {
  padding-top: 60px;
}
.mobile-frame-img {
  position: absolute;
  top: 52px;
  left: 2px;
  height: calc(100% + 108px);
  width: calc(100% - 4px);
  z-index: 5;
}
.slick-slide img {
  border-radius: 10%;
}
.margin-left {
  margin-left: 50px !important;
}
.process-slider {
  margin: auto;
  width: calc(250px - 4px);
  height: calc(541px - 108px);
  position: relative;
  border-radius: 37px;
}
.card-margin {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 60px;
}
.priv {
  display: flex;
  margin-left: 0 !important;
  flex-direction: inherit;
  margin-right: 0 !important;
  justify-content: center;
}
#services {
  background-position: center;
//   background-image: linear-gradient(
//       rgb(255 255 255 / 0.95),
//       rgb(243 243 243 / 0.95)
//     ),
//     url(images/home/watermark/services_back1.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.client-img-div {
  justify-content: center;
  align-items: center;
  min-height: 200px !important;
  display: flex !important;
}
.client-slider img {
  max-width: 50%;
  opacity: 0.8;
  margin: auto;
  height: auto;
}
.slick-dots li button:before {
  color: #d10513 !important;
}
.slick-dots li.slick-active button:before {
  color: #d10513 !important;
  font-size: 8px !important;
}
#canvas {
  position: relative;
  margin: 1em auto;
}
.ball {
  position: absolute;
  display: inline-block;
  background-size: cover;
}
#centerDiv {
  animation: border 2s;
}
@keyframes border {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.dropdown-custom {
  padding: 0;
  margin: 0;
}
.icon-check {
  color: #fff;
  position: absolute;
  height: 37px;
  width: 37px;
  border-radius: 90px;
  right: 53px;
  top: 32px;
  font-size: 16px;
  line-height: 37px;
  background: #d10513;
}
#serviceSec {
  background-image: linear-gradient(
      rgb(255 255 255 / 0.97),
      rgb(243 243 243 / 0.98)
    ),
    url(../../assets/images/home/watermark/services_back1.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.service-img {
  display: flex;
}
.service-img img {
  margin: 0 auto;
  max-width: 30%;
}
.each-service {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  position: relative;
}
.service-title {
  padding: 4px 0;
  color: #3c389f;
  font-size: 19px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}
.service-details {
  font-size: 13px;
  text-align: center;
}
.choose-img img {
  max-width: 89%;
  max-height: 87%;
  display: flex;
  margin: 0 auto;
}
.choose-headline {
  padding-bottom: 36px;
}
.why-choose {
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  justify-content: center;
  padding: 30px;
}
.why-choose img {
  max-width: 80px;
}
.choose-icon-text h4 {
  font-size: 20px;
  color: #3c389f;
  text-align: end;
}
.choose-icon-text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 11px;
}
.choose-details {
  font-size: 14px;
  text-align: end;
  margin-top: 6px;
}
.choose-details-2 {
  font-size: 14px;
  margin-top: 6px;
}
.why-choose-2 img {
  max-width: 85px;
}
.why-choose-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.choose-icon-text-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 16px;
}
.choose-icon-text-2 h4 {
  font-size: 20px;
  color: #3c389f;
}
.typewriter-text {
  padding-right: 10px;
  color: var(--primary);
  border-right: solid #d10513 3px;
  text-transform: uppercase;
  animation: type-writer-cursor 1s ease-in-out infinite;
  font-weight: 700;
  font-size: 24px;
}
@keyframes type-writer-cursor {
  from {
    border-color: #d10513;
  }
  to {
    border-color: transparent;
  }
}
#testimonialSec {
  background: #ececec
    url(https://spaceraceit.com/wp/acrony/home2/wp-admin/admin-ajax.php?action=kc_get_thumbn&type=filter_url&id=%2Fwp-content%2Fuploads%2F2019%2F04%2Fpppo.png)
    center bottom/100% auto no-repeat scroll;
}
.client-img {
  border-radius: 50%;
  overflow: hidden;
  background-color: #677ced1f;
  height: 17rem;
  width: 17rem;
  padding: 2rem;
}
.client-img img {
  width: 100%;
  vertical-align: middle;
  max-height: 25rem;
  margin-left: auto;
  margin-right: auto;
}
.flex.vcenter {
  align-items: center;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.client-wrap {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.stars {
  max-height: 1.2rem;
  margin-bottom: 0.5rem;
}
.client-quote {
  color: #2d3a58;
  text-align: justify;
}
.client-name {
  margin-top: 20px;
  color: #3c389f;
  text-align: left;
  margin-right: 15px;
}
.client-position {
  color: #000000bf;
}
.testi-icon {
  position: absolute;
  top: 57%;
  right: 10%;
  width: 33%;
}
.testmonial-slider {
  margin-top: 5%;
}
.testmonial-slider .slick-list {
  height: 360px;
}
.testmonial-slider .slick-next {
  left: 31%;
  top: 75%;
  background: url(../../assets/images/home/client/right-arrow.png);
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  position: absolute !important;
  background-repeat: no-repeat;
  background-position: center;
  border: solid 5px #fff;
  background-color: #3c389f;
  background-size: 15px;
}
.testmonial-slider .slick-prev {
  left: 24%;
  top: 75%;
  background: url(../../assets/images/home/client/left-arrow.png);
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  position: absolute !important;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1 !important;
  z-index: 2;
  border: solid 5px #fff;
  background-color: #3c389f;
  background-size: 15px;
}
.tab__button1::after {
  content: '';
  background: #3c389f;
  position: absolute;
  padding: 2px 79px;
  margin-top: 22px;
  margin-right: 19px;
  margin-left: -108px;
}
.tab__button1-active::after {
  background: #d10513;
}
.tab__button1:hover::after {
  background: #d10513;
}
.tab__button1:focus::after {
  background: #d10513;
}
.tab__button2::after {
  content: '';
  background: #3c389f;
  position: absolute;
  padding: 2px 79px;
  margin-top: 22px;
  margin-right: 19px;
  margin-left: -126px;
}
.tab__button2:hover::after {
  background: #d10513;
}
.tab__button2:focus::after {
  background: #d10513 !important;
}
.tab__button3::after {
  content: '';
  background: #3c389f;
  position: absolute;
  padding: 2px 79px;
  margin-top: 22px;
  margin-right: 19px;
  margin-left: -126px;
}
.tab__button3:hover::after {
  background: #d10513;
}
.tab__button3:focus::after {
  background: #d10513 !important;
}
.tab__container {
  margin: auto;
  border-radius: 5px;
  padding: 30px;
}
.tab__title {
  margin: 25px 0 0;
}
.tab__title .tab__button:first-of-type {
  margin: 0;
}
.tab__title .tab__button {
  color: #3c389f;
  border: 1px solid transparent;
  background: 0 0;
  outline: 0;
  margin: 0 -5px;
  cursor: pointer;
  width: 33%;
}
.tab__title .tab__button:hover {
  color: #d10513;
}
.tab__title .tab__button img {
  height: 40px;
  display: block;
  margin: 0 auto 5px;
  transition: filter 0.3s ease;
}
.tab__title .tab_button-active {
  color: #d10513;
}
.tab__title .tab_button-active img {
  filter: none;
}
.tab__inside {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  visibility: hidden;
  opacity: 0;
  height: 0;
  transform: translateY(10px);
  transition: transform 0.3s ease-in;
}
.tab__inside .tab__inside-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.tab__inside .tab__inside-img img {
  height: 200px;
  display: block;
  margin: 0 auto;
}
.tab__inside .tab__inside-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.tab__inside .tab__inside-text p {
  color: #545454;
  font-size: 14px;
  font-weight: 100;
}
.tab__inside .tab__inside-text button {
  border-radius: 30px;
  text-transform: uppercase;
  padding: 10px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100px;
}
.tab__inside .tab__inside-text button:hover {
  color: #3c389f;
}
.tab__inside-active {
  margin-top: 50px;
  visibility: visible;
  opacity: 1;
  height: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease-out 0.1s, opacity 0.3s ease-out 0.1s;
}
