@import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

$font-family-sans-serif: 'Nunito', sans-serif;
$font-weight-bolder: 900;
$primary: #e83330;
$accent: #e83330;
$accent2: #fc9c9a;
$primary-secondary: #0009;
$light: #fff;
$black: #000;
$secondary: #5a6770;

$linear-gradient: linear-gradient(125.09deg, $primary 100%, $primary 87.5%);

$btn-padding-y: 0.7rem;
$btn-padding-x: 1.5rem;
$btn-hover-bg-shade-amount: 20%;
$btn-hover-bg-tint-amount: 20%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 20%;
$btn-transition: transform 0.15s ease-in-out, color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.5rem;
$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 2rem;

$box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
  rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
$box-shadow-sm: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
  rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
$box-shadow-lg: 0px 28px 52px rgba(0, 0, 0, 0.04);

$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
);
$border-radius: 6px;
$border-radius: 4px;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;
$border-radius-pill: 50rem;
$border-color: #d4d4d5;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$input-border-radius: 0.5rem;

$success: #43a047;

$primary-text: #5a6770;
$gray-100: #f0f2f5;

*:focus {
  box-shadow: none !important;
}

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/root';
@import 'bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

$utilities: map-merge(
  $utilities,
  (
    'cursor': (
      property: cursor,
      class: cursor,
      responsive: true,
      values: auto pointer grab,
    ),
  )
);

$euiColorPrimary: #e83330;
$euiColorAccent: #e83330;
$euiColorGreen: #43a047;
$euiColorSuccess: #43a047;
$euiSize: 14px;
$euiBorderRadius: 4px;
// Global styling
@import '@elastic/eui/src/themes/amsterdam/theme_light';

@import './base';
@import './components/accordion';
@import './components/navbar';
@import './components/alert';
@import './components/home';
@import './components/homeres';
@import './override';
