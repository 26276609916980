.alert {
  z-index: 2500000;
  position: fixed;
  top: 2rem;
  left: 50%;
  display: flex;
  transform: translate(-50%, 0);

  &-body {
    background-color: $white;
    display: none;
    padding: 1rem;
    grid-gap: 0.7rem;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 22px -1px;
    @extend .rounded-1;
    width: clamp(300px, 95vw, 460px);

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5rem;
      width: 1.5rem;
      color: $white;
      font-size: 1rem;
      border-radius: 50%;
      background-color: $green-400;
    }
  }

  &-btn {
    border: none;
    outline: none;
    background-color: transparent;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    @extend .text-primary;
  }

  &.show {
    .alert-body {
      display: flex;
      animation: alert-pop 0.5s ease-out forwards;
      .icon {
        svg {
          padding: 2.5px;
        }
      }
    }
  }

  &.error {
    .alert-body {
      .icon {
        background-color: rgba(216, 12, 12, 0.856);
      }
    }
  }

  &.warn {
    .alert-body {
      .icon {
        background-color: orange;
      }
    }
  }
}

@keyframes alert-pop {
  0% {
    opacity: 0;
    transform: translate(0, 2rem);
  }
  80% {
    opacity: 1;
    transform: translate(0, -10%);
  }
  100% {
    transform: translate(0, 0);
  }
}
