@use './custom' as *;

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  max-width: 100vw !important;
  overflow-x: hidden !important;
  color: $primary-text !important;
  @extend .bg-gray;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .footer {
    background: #2c2727;
    margin-top: auto;
  }
}

input[type='text'] {
  background-color: transparent;
}

.loading-screen {
  z-index: 1000;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;

  .logo {
    animation: fade-in-out 2s ease-in-out infinite;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px !important;

  .logo {
    animation: fade-in-out 2s ease-in-out infinite;
  }
}

.footer-logo {
  margin-top: -15px;

  @media screen and (min-width: 900px) {
    margin-left: -10px;
  }
}

@keyframes fade-in-out {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes on-mount {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}
