.navbar {
  @media screen and (max-width: 995px) {
    padding: 0;

    .nav-menu {
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      height: 100vh;
      padding-top: 3rem;
      transition: $btn-transition;
      transform: translateX(-200%);

      &.active {
        transform: translateX(0);
      }
    }
  }

  .nav-link {
    transition: $btn-transition;

    &:hover {
      background-color: $light;
    }
    @media screen and (max-width: 995px) {
      width: 100%;
      text-align: center;
    }
  }
}
