.display-2 {
  font-weight: $font-weight-bolder;
}

.card,
.accordion-item {
  border-color: $border-color;
}

.btn {
  line-height: 1;

  &-text {
    border: none;
    padding: 0.8rem 0.8rem;

    &:hover {
      background-color: $light;
      color: $primary;
    }
  }

  &-light {
    transition: $btn-transition, filter 0.15s ease-in-out;

    &:hover {
      filter: brightness(0.8);
    }
  }
}

.navbar {
  .toggler {
    color: inherit;
    margin: 0;
    padding: 0.5rem;
    border: none;
  }

  .icon-btn {
    font-size: 2rem;
    color: $primary;
  }
}

.text-bolder {
  font-weight: 900 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.bg-gray {
  background-color: #f7f8fc !important;
}

.bg-linear {
  background-color: transparent;
  background: $linear-gradient;
}

.home-banner {
  // background: url(../assets/images/home/dm.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  padding-top: 85px;
  padding-bottom: 85px;
  background: linear-gradient(145deg, white 70%, #f0f2f5 80%);

  @media (min-width: 465px) {
    padding-top: 145px;
    padding-bottom: 185px;
  }
}

.section-screen {
  background: url(../assets/images/home/si.png);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: right bottom;
  position: relative;
  padding-bottom: 80px;

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    background: $accent;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
  }
}

.subtitle {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $primary;
  font-size: 0.8rem;
}

.w-content {
  width: max-content !important;
}

.link,
a {
  text-decoration: none;
}

.divider {
  background-color: $gray-300;
}

.section {
  // padding: 6rem 0;
}

.form-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    font-weight: bolder;
    text-decoration: none;
    background-color: rgba(133, 129, 129, 0.09);
    font-size: 16px;

    &:first-child {
      color: $accent;
      margin-right: 5px;
    }
    &:last-child {
      color: green;
    }
  }
}

button[disabled] {
  color: gray !important;
  background: #f8d1d2 !important;
}

* {
  /* width */
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ebebeb !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aeaeae !important;
  }
}

.banner-character {
}

.services {
  position: relative;
  transform: translateY(-50%);

  img {
    height: 60px;
  }
}

@media screen and (max-width: 600px) {
  .banner-character {
    position: absolute;
    transform: translate3d(50%, 0, 0);
    z-index: -1;
    opacity: 0.3;
    bottom: 0;
  }
  .services img {
    height: 25px;
  }
  .home-banner {
    height: 100vh;
    overflow: hidden;
  }
}

.my-tab {
  &-btns {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(5, minmax(min-content, max-content));
    overflow-x: auto;
  }

  &-btn {
    min-width: 150px;
    padding: 30px 45px;
    border-radius: 6px;
    background: #f9f9f9;
    text-align: center;
    img {
      filter: contrast(0) brightness(0);
    }
    &.active {
      background: $accent;
      img {
        filter: contrast(1) brightness(9.5);
      }
      h4 {
        color: white !important;
      }
    }
  }

  &-pane {
    img {
      border-radius: 24px;
    }
    h2 {
      margin-left: 15px;
    }
    ul {
      li {
        list-style: none;
        padding: 15px 15px;
        display: flex;
        line-height: 28px;
        &::before {
          content: '•';
          display: inline-block;
          color: #ffd995;
          margin-right: 15px;
        }
      }
    }
  }
}

.partners {
  padding-top: 100px;
  padding-bottom: 100px;
}

.large-text-heading {
  max-width: 60%;
  font-size: 56px;
  line-height: 120%;
}

.accordian {
  max-width: 60%;
  margin: auto;

  .accordion-button,
  button.collapsed,
  a.collapsed {
    block-size: auto !important;
  }
}

.accordion-item {
  padding: 20px;
}

.accordion-header {
  // padding: 15px 25px;
}

.accordion-button {
  color: inherit !important;
  box-shadow: none !important;
  background-color: white !important;
  padding: 0.5rem 0.85rem !important;

  span {
    font-weight: bold !important;
  }
}

.accordion-button::after,
.accordion-button::before {
  background-color: #b6b6b6 !important;
}

.accordion-collapse.collapse.show {
  // padding: 15px 25px;
  // padding-top: 0;
}

.get-started {
  background-color: #ffefee;
  background-image: url('../assets/images/get-started-dotted-map.svg');
  // background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  p {
    max-width: 684px;
    margin: auto;
  }
}

.pricing-table-whole {
  ul li {
    font-size: 1.2rem;
  }
}

.price-table-home {
  width: 100%;

  td,
  th {
    padding: 25px !important;
  }
  td {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}

.table > :not(:first-child) {
  border-top: 2px solid $border-color;
}

.gray-img {
  filter: grayscale(0.9) hue-rotate(0deg) brightness(1.1) contrast(1.1);
}

.tracking-status-text {
  font-size: 12px !important;
}

@media screen and (max-width: 600px) {
  body {
    width: 100vw;
    overflow-x: hidden !important;
  }
  .partners {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .large-text-heading {
    max-width: 80%;
    font-size: 32px;
  }

  .accordian {
    max-width: 100%;
  }

  .accordion-button {
    span {
      font-size: 0.95rem !important;
    }
  }

  .pricing-table-whole {
    ul li {
      font-size: 0.9rem;
    }
  }

  .tracking-status-text {
    font-size: 10px !important;
  }
}

.hidden-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.section-border {
  border: 1px solid #ebeef0;
}

.tracking-flex.steps {
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: row !important;

  .step {
    flex: 1 1 20% !important;
  }
}
