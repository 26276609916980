﻿@media (min-height: 1024px) and (min-width: 1300px) {
  .hero-section {
    height: auto;
  }
}
@media (max-width: 1199px) {
  .typewriter-text {
    font-size: 28px;
  }
  .tracking-input > .textbox {
    width: 285px;
  }
  .tracking-input > .button {
    width: 86px;
  }
  #serviceTopSec {
    height: 0;
  }
  .new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url(../../assets/images/home/atc-Recovered-1199.png) no-repeat
      scroll center 0;
    width: 102%;
    background-size: cover;
    height: 415px;
  }
  .new_footer_top .footer_bg .footer_bg_one {
    bottom: 8px;
    animation: myfirst 17s linear infinite;
  }
  .new_footer_top .footer_bg .footer_bg_two {
    bottom: -12px;
    animation: myfirst 13s linear infinite;
  }
}
@media (max-width: 1100px) {
  .client-wrap {
    padding: 0 24px;
  }
}
@media (max-width: 1024px) {
  .hero-section {
    height: auto;
  }
  .each-service {
    justify-content: normal;
  }
  .banner-left-top-img {
    height: 278px;
  }
  .typewriter-text {
    font-size: 26px;
  }
  .choose-icon-text h4 {
    font-size: 17px;
  }
  .choose-icon-text-2 h4 {
    font-size: 17px;
  }
  .banner-content {
    margin-left: 17px;
  }
  .tracking-input {
    display: flex;
  }
  .tracking-input > .textbox {
    width: 259px;
  }
  .tracking-input > .button {
    width: 92px;
  }
  .choose-icon-text h4 {
    font-size: 15px;
    font-weight: 600;
  }
  .choose-icon-text-2 h4 {
    font-size: 15px;
    font-weight: 600;
  }
  #serviceTopSec {
    height: 0;
  }
  h2.section-headline {
    font-size: 30px;
  }
  .each-service {
    justify-content: end;
  }
  .section-padding {
    padding: 75px 0;
  }
  .choose-headline {
    padding-bottom: 16px;
  }
  .client-wrap {
    padding: 0 24px;
  }
  .service-title {
    font-size: 18px;
  }
  .choose-icon-text h4 {
    font-size: 17px;
  }
  .why-choose-2 {
    padding: 25px;
  }
  .choose-icon-text-2 h4 {
    font-size: 17px;
  }
  .banner-content {
    margin-left: 23px;
  }
  .new_footer_top .footer_bg .footer_bg_one {
    animation: myfirst 14s linear infinite;
  }
  .new_footer_top .footer_bg .footer_bg_two {
    animation: myfirst 11s linear infinite;
  }
}
@media (max-width: 990px) {
  .drone-moblie {
    display: none;
  }
}
@media (max-width: 991px) {
  .lang-dropdown-mob {
    position: absolute !important;
  }
  .sub-menu-div-service {
    left: calc(50% - 390px) !important;
    width: 781px !important;
  }
  .another-services::before {
    top: 96px;
    padding: 0 37px;
    left: -93px;
  }
  .borderright::after {
    left: 165px;
  }
  .borderrightt::after {
    right: 148px;
  }
  .banner-left-top-img {
    height: 227px;
    text-align: center;
    padding-top: 14px;
  }
  .hero-section {
    height: auto;
  }
  .typewriter-text {
    font-size: 27px;
  }
  #navBeforeScroll {
    text-align: right;
  }
  .tracking-input {
    display: flex;
    margin-bottom: 45px;
  }
  .tracking-input > .textbox {
    width: 185px;
  }
  .tracking-input > .button {
    width: 60px;
  }
  .section-padding {
    padding: 85px 0;
  }
  .service-title {
    font-size: 18px;
  }
  #serviceTopSec {
    height: 0;
  }
  h2.section-headline {
    font-size: 27px;
  }
  .each-service {
    justify-content: end;
  }
  .why-choose {
    padding: 24px;
  }
  .choose-img img {
    display: none;
  }
  .choose-icon-text h4 {
    font-size: 16px;
    text-align: end;
  }
  .choose-headline {
    padding-bottom: 28px;
  }
  .choose-details {
    font-size: 13px;
  }
  .why-choose-2 img {
    max-width: 66px;
  }
  .choose-icon-text-2 h4 {
    font-size: 16px;
  }
  .choose-details-2 {
    font-size: 13px;
  }
  .navbar-light .navbar-toggler-icon {
    // background-image: url(../../assets/images/menu-icon/menu.png) !important;
    margin-right: -26px;
  }
  .toggler-ipad {
    background-color: #d10513;
    margin-right: 17px;
    margin-top: 7px;
    margin-bottom: 10px;
  }
  .collapse-tab {
    position: absolute;
    width: 100%;
    text-align: left;
    background-color: #fff;
  }
  .lang-mobile {
    margin-left: -10px;
  }
  .why-choose-2 {
    padding: 25px;
  }
  .client-wrap {
    margin: 27px 0 0 1px;
    padding: 0 10px;
  }
  .new_footer_top {
    margin-top: 0;
  }
  .banner-content {
    margin-left: 27px;
  }
  .drone-moblie {
    display: none;
  }
  .testmonial-slider .slick-list {
    height: 540px;
  }
  .testmonial-slider .slick-next {
    left: 51%;
    top: 49%;
  }
  .testmonial-slider .slick-prev {
    left: 44%;
    top: 49%;
  }
  .new_footer_top .footer_bg .footer_bg_one {
    animation: myfirst 13s linear infinite;
    bottom: 8px;
  }
  .new_footer_top .footer_bg .footer_bg_two {
    animation: myfirst 11s linear infinite;
    bottom: -12px;
  }
  .new_footer_top .f_social_icon a {
    width: 35px;
    height: 35px;
    line-height: 33px;
  }
  .new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url(../../assets/images/home/atc-Recovered-991.png) no-repeat
      scroll center 0;
    width: 102%;
    background-size: cover;
    height: 415px;
  }
}
@media (max-width: 767px) {
  .hero-section {
    height: auto;
  }
  .borderright::after {
    left: 205px;
  }
  .borderrightt::after {
    right: 191px;
  }
  .drone-moblie {
    display: none;
  }
  .sub-menu-div-service {
    left: calc(50% - 284px) !important;
    width: 570px !important;
  }
  #serviceSec {
    background-image: none;
  }
  .nav-item > a {
    font-size: 14px;
  }
  .navbar-light .navbar-toggler-icon {
    // background-image: url(../../assets/images/menu-icon/menu.png) !important;
    margin-right: -26px;
  }
  .toggler-ipad {
    background-color: #d10513;
    margin-right: 17px;
    margin-top: 7px;
  }
  .lang-mobile {
    margin-left: -10px;
  }
  .lang-dropdown-mob {
    position: absolute !important;
    margin-top: 3px;
    margin-left: 9px;
    width: 93px;
    border-top: none;
  }
  .nav-item {
    border-top: none;
  }
  .nav-item > a .mob-item {
    padding: 0 24px !important;
  }
  .nav-top-logo {
    height: 28px;
  }
  .japax {
    font-size: 17px;
    margin-left: 15px;
  }
  .banner-left-top-img {
    height: 288px;
    text-align: center;
    padding-top: 15px;
  }
  .feature-service-tab {
    margin-top: 33px;
  }
  .another-services::before {
    top: 3px;
    left: 13px;
    width: 98%;
    transform: none;
    text-align: center;
  }
  .drone-moblie {
    display: none;
  }
  .typewriter-text {
    font-size: 25px;
  }
  h2.section-headline {
    font-size: 25px;
  }
  .service-title {
    font-size: 16px;
    margin-top: 11px;
  }
  .each-service {
    justify-content: end;
  }
  .play-icon {
    display: none;
  }
  .icon-check {
    right: 13px;
    top: 4px;
  }
  .section-padding {
    padding: 0;
  }
  #whyChooseSec {
    margin-top: 45px;
  }
  .why-choose-2 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
  }
  .why-choose {
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
  }
  .choose-icon-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 16px;
  }
  .choose-details {
    margin-top: 15px;
    text-align: left;
  }
  .choose-icon-text-2 h4 {
    font-size: 16px;
  }
  .choose-icon-text h4 {
    font-size: 16px;
  }
  .choose-headline {
    padding-top: 10px;
  }
  .testmonial-slider .slick-list {
    height: 570px;
  }
  .testmonial-slider .slick-prev {
    left: 43%;
    top: 45%;
  }
  .testmonial-slider .slick-next {
    left: 50%;
    top: 45%;
  }
  .client-wrap {
    padding: 34px 17px;
    text-align: justify;
  }
  .testimonial-headline {
    padding-top: 43px;
  }
  .new_footer_top {
    margin-top: 0;
  }
  .link-social-icon {
    width: 130px;
  }
  .tracking-input > .textbox-footer {
    width: 255px;
  }
  .tracking-input > .button {
    width: 84px;
  }
  .mt-15 {
    margin-top: 15px;
    margin-left: 30px;
  }
  .mt-25 {
    margin-top: 25px;
    margin-left: 30px;
  }
  .banner-content {
    margin-left: 48px;
  }
  .f_social_icon {
    margin-left: -31px;
  }
  .new_footer_top .f_social_icon a {
    width: 36px;
    height: 36px;
  }
  .each-service {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-img {
    display: flex;
  }
  .service-img img {
    max-width: 38%;
  }
  .choose-img img {
    display: none;
  }
  .new_footer_top .footer_bg {
    position: absolute;
    bottom: 0;
    background: url(../../assets/images/home/atc-Recovered-767.png) no-repeat
      scroll center 0;
    width: 103%;
    background-size: cover;
    height: 430px;
  }
}
@media (max-width: 576px) {
  .tab__container {
    padding: 0;
  }
  .tab__inside {
    display: block;
  }
  #serviceSec {
    background-image: none;
  }
  .sub-menu-div-service {
    width: calc(100% - 4px) !important;
  }
  .lang-dropdown-mob {
    height: 99px !important;
    position: absolute !important;
    margin-top: -62px !important;
    margin-left: 5px !important;
    width: 93px !important;
    border-top: none !important;
  }
  .dropdown-menu.show {
    top: 89px !important;
    left: 4px !important;
    overflow: scroll;
    height: 362px;
  }
  .play-icon {
    display: none;
  }
  .tab-text {
    margin-bottom: 3rem;
    margin-top: 3rem;
    display: block;
  }
  .hero-section {
    height: auto;
  }
  .navbar-light .navbar-toggler-icon {
    // background-image: url(../../assets/images/menu-icon/menu.png) !important;
    margin-right: 6px;
    margin-top: 29px;
  }
  .nav-item > a {
    font-size: 12px;
    padding: 1px 25px !important;
  }
  .nav-item {
    border-top: none;
  }
  .nav-mob-collapse {
    position: absolute;
    width: 96%;
    padding: 5px;
    background-color: #b73c3c;
    z-index: 9999;
    height: 167%;
    margin-top: 145px;
  }
  .nav-mob-color {
    background-color: transparent;
  }
  .lang-mobile {
    margin-left: -10px;
  }
  .drone-moblie {
    display: none;
  }
  .banner-left-top-img {
    height: 207px;
    padding-bottom: 8px;
    padding-top: 13px;
  }
  .typewriter-text {
    font-size: 22px;
  }
  .hero-section {
    padding: 5px 15px;
  }
  .tracking-input {
    margin-bottom: 0;
  }
  .tracking-input > .textbox {
    width: 193px;
  }
  .tracking-input > .button {
    width: 108px;
  }
  .tracking-input {
    display: flex;
  }
  #serviceSec {
    background-image: none;
  }
  #serviceTopSec {
    height: 0;
  }
  .section-padding {
    padding: 36px 0;
  }
  h2.section-headline {
    font-size: 20px;
  }
  .vh-100 {
    height: 100% !important;
  }
  .icon-check {
    right: 86px;
    top: 57px;
  }
  .choose-headline {
    padding-bottom: 19px;
  }
  .why-choose img {
    max-width: 66px;
  }
  .choose-img img {
    display: none;
  }
  .choose-icon-text h4 {
    font-size: 16px;
    font-weight: 600;
  }
  .choose-details {
    font-size: 13px;
  }
  .choose-img {
    text-align: center;
  }
  .choose-img img {
    max-width: 60%;
  }
  .choose-icon-text-2 h4 {
    font-size: 16px;
    font-weight: 600;
  }
  .choose-details-2 {
    font-size: 13px;
  }
  .why-choose-2 img {
    max-width: 66px;
  }
  .client-img {
    border-radius: 50%;
    overflow: hidden;
    background-color: #677ced1f;
    height: 14rem;
    width: 14rem;
    padding: 2rem;
  }
  .testmonial-slider .slick-prev {
    left: 35%;
    top: 33%;
  }
  .testmonial-slider .slick-next {
    left: 52%;
    top: 33%;
  }
  .play-icon {
    display: none;
  }
  .new_footer_top {
    margin-top: 0;
    padding-top: 45px;
  }
  .banner-content {
    margin-left: 0;
    margin-bottom: 14px;
  }
  .new_footer_top .f-title {
    margin-bottom: 10px;
  }
  .testmonial-slider .slick-list {
    height: 661px;
  }
  .client-wrap {
    padding: 40px 16px 16px;
    margin-left: 0;
  }
  .client-quote {
    text-align: justify;
    font-size: 15px;
  }
  .social-link-mobile {
    padding-top: 15px;
  }
  .link-social-icon {
    margin-left: 5px;
    width: 145px;
  }
  #whyChooseSec {
    margin-top: -28px;
  }
  .testimonial-headline {
    padding-top: 0;
  }
  .feauture-mob-service {
    background-color: #f7f6f691;
  }
  .feature-mob::before {
    content: 'Featured Services';
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    top: 545px;
    left: 0;
    position: absolute;
    background: #3c389f;
    width: 100%;
    text-align: center;
  }
  .mt-23 {
    margin-top: 23px;
  }
  .each-service {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-img {
    display: flex;
  }
  .service-img img {
    max-width: 37%;
  }
  .new_footer_top .footer_bg {
    position: absolute;
    bottom: 15px;
    background: url(../../assets/images/home/atc-Recovered-crop04.png) no-repeat
      scroll center 0;
    width: 104%;
    background-size: cover;
    height: 439px;
  }
  .new_footer_top .footer_bg .footer_bg_one {
    bottom: 0;
    animation: myfirst 9s linear infinite;
    width: 194px;
  }
  .new_footer_top .footer_bg .footer_bg_two {
    bottom: 10px;
    width: 101px;
    height: 50px;
    animation: myfirst 6s linear infinite;
  }
  .mt-15 {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .new_footer_top .footer_bg {
    height: 397px;
  }
}
@media (max-width: 479px) {
  #topNav.navbar-top .header-info-group:nth-child(2) {
    width: 70%;
  }
  .navbar-light .navbar-toggler-icon {
    margin-top: 7px;
  }
  .service-img img {
    max-width: 40%;
  }
  .nav-mob-collapse {
    position: absolute;
    width: 96%;
    padding: 5px;
    background-color: #b73c3c;
    z-index: 9999;
    height: 130%;
    margin-top: 145px;
  }
  .lang-dropdown-mob {
    position: absolute !important;
    margin-top: 3px;
    margin-left: 9px;
    width: 93px;
    border-top: none;
  }
}
@media (max-width: 320px) {
  .service-img img {
    max-width: 50%;
  }
  .tracking-input > .button {
    width: 98px;
  }
}
@media (max-width: 300px) {
  .tracking-input > .button {
    width: 54px;
  }
  .banner-left-top-img {
    height: 193px;
  }
  .typewriter-text {
    font-size: 18px;
  }
  .link-social-icon {
    margin-top: 8px;
  }
  .new_footer_top .footer_bg .footer_bg_one {
    width: 206px;
    animation: myfirst 8s linear infinite;
  }
  .new_footer_top .footer_bg .footer_bg_two {
    animation: myfirst 5s linear infinite;
  }
}
