.accordion-button {
  position: relative;

  &::after,
  &::before {
    background-image: none !important;
    content: '';
    position: absolute;
    top: 50%;
    right: 1.1rem;
    background-color: $primary;
    height: 1.1rem;
    width: 2px;
    transition: $btn-transition;
  }

  &::after {
    transform: translateY(-50%) rotate(45deg) !important;
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg) !important;
  }

  &.collapsed {
    &::after {
      transform: translateY(-50%) rotate(0) !important;
    }

    &::before {
      transform: translateY(-50%) rotate(90deg) !important;
    }
  }
}
